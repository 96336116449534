import { Link } from "react-router-dom";
import BackArrowSvg from "../svg/BackArrowSvg";

export const BackNavigation = () => {
  return (
    <div
      style={{
        justifyContent: "flex-start",
        display: "flex",
        alignItems: "center",
        marginTop: 27,
        marginLeft: 24,
      }}
    >
      <Link to="/" style={{ textDecoration: "none", color: "#525252" }}>
        <BackArrowSvg />
        <text style={{ marginLeft: 16, fontWeight: 600 }}>Wróć</text>
      </Link>
    </div>
  );
};

import { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BackNavigation } from "../components/BackNavigation";
import BackArrowSvg from "../svg/BackArrowSvg";
import ValidSvg from "../svg/ValidSvg";

function FormScreen() {
  const [message, setMessage] = useState("");
  const [images, setImages] = useState<File[]>([]);

  const { state } = useLocation();

  const canSubmit = useMemo(() => {
    return message.length > 0;
  }, [message]);

  const onMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const fileUploadImage = (event: any) => {
    const imgs = event.target.files as File[];
    setImages([...images, ...imgs]);
  };

  // const handleSubmit = (e: any) => {
  //   console.log("testurl", testUrl);

  //   let path = `sms:${number}?body=${testUrl}`;
  //   if (isSubmit === true) {
  //     window.location.href = path;
  //   } else setIsSubmit(false);
  // };

  const sendVia = useMemo(() => {
    if (Boolean(state?.number)) {
      return "sms";
    }
    if (Boolean(state?.email)) {
      return "email";
    }
    return "none";
  }, [state]);

  const onSubmit = () => {
    if (sendVia === "sms") {
      handleSMS();
    } else if (sendVia === "email") {
      handleEmail();
    }
  };

  const handleSMS = () => {
    const phoneNumber = state.number as string;
    const link = `sms:${phoneNumber}?&body=${encodeURIComponent(message)}`;
    window.open(link);
  };

  const handleEmail = () => {
    const email = state.email as string;
    const link = `mailto:${email}?&body=${encodeURIComponent(message)}`;
    window.open(link);
  };
  // const handleShare = async () => {
  //   const data: ShareData = {
  //     files: images,
  //     text: message,
  //   };

  //   try {
  //     if (navigator.canShare(data)) {
  //       await navigator.share(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const navigate = useNavigate();
  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === "visible") {
        // avoid navigating when the sms/email is loading
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    };
    document.addEventListener("visibilitychange", listener);
    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [navigate]);

  return (
    <div>
      <BackNavigation />
      <div
        className="bg-white border-bottom"
        style={{
          padding: 24,
          justifyContent: "flex-start",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%" }}>
          <h2 style={{ fontSize: 18, color: "#525252", fontWeight: 500 }}>
            {sendVia === "sms"
              ? "Napisz wiadomość SMS"
              : "Napisz wiadomość - mail"}
          </h2>
          <Form>
            <Form.Group>
              <Form.Label
                style={{
                  fontSize: 14,
                  color: "#525252",
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: 0,
                }}
              >
                Treść wiadomości
              </Form.Label>
              <Form.Control
                as="textarea"
                style={{ width: "100%", minHeight: 150 }}
                value={message}
                onChange={onMessageChange}
              />
              {!canSubmit ? (
                <div
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    display: "flex",
                    marginTop: 5,
                  }}
                >
                  <ValidSvg />
                  <span
                    style={{ color: "red", fontSize: 12, textAlign: "start" }}
                  >
                    Wiadomość musi zawierać tekst
                  </span>
                </div>
              ) : null}
            </Form.Group>
          </Form>
        </div>
        {/* 
        <Row
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 40,
            display: "flex",
            width: "100%",
          }}
        >
          {images.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 104,
                  height: 104,
                  borderRadius: 5,
                  marginTop: 10,
                  display: "flex",
                  padding: 0,
                  marginRight: 10,
                }}
              >
                <img
                  src={item ? URL.createObjectURL(item) : "none"}
                  alt={`${item.name}-${index}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 5,
                    display: "flex",
                  }}
                ></img>
              </div>
            );
          })}
          <>
            <label
              style={{
                width: 104,
                height: 104,
                backgroundColor: "#FAFAFA",
                borderStyle: "dashed",
                borderWidth: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <PlusSvg />
              <text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#1A1818",
                  opacity: 0.45,
                  paddingTop: 10,
                }}
              >
                Dodaj zdęcie
              </text>
              <input
                type="file"
                multiple
                name="myImage"
                style={{ width: 60, height: 60 }}
                hidden
                onChange={fileUploadImage}
              />
            </label>
          </>
        </Row> */}
      </div>
      <Button
        onClick={onSubmit}
        style={{
          backgroundColor: "#525252",
          paddingLeft: 35,
          paddingRight: 35,
          borderRadius: 15,
          marginTop: 30,
          borderWidth: 0,
          paddingTop: 10,
          paddingBottom: 10,
        }}
        disabled={!canSubmit}
      >
        Wyślij
      </Button>
      {/* </a> */}
    </div>
  );
}
export default FormScreen;

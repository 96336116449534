import { useState, useEffect, useCallback } from "react";
import { isMobileSafari, isSamsungBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";

let deferredPrompt: any = null;

export const useAddToHome = () => {
  const shouldAddHomeButtonBeVisible = () => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      return false;
    }
    // standalone will appear if app is added to home screen on ios
    // @ts-ignore
    if (navigator?.standalone) {
      return false;
    }
    return true;
  };
  const navigate = useNavigate();

  const [addToHomeAvailable, setAddToHomeAvailable] = useState(
    shouldAddHomeButtonBeVisible || Boolean(deferredPrompt)
  );
  // beforeinstallprompt will only be fired when some conditions are true :
  // The PWA must not already be installed
  // Meets a user engagement heuristic (previously, the user had to interact with the domain for at least 30 seconds, this is not a requirement anymore).
  // Your web app must include a web app manifest.
  // Your web app must be served over a secure HTTPS connection.
  // Has registered a service worker with a fetch event handler.
  useEffect(() => {
    const onInstallPrompt = (e: any) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;

      setAddToHomeAvailable(true);
    };
    window.addEventListener("beforeinstallprompt", onInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", onInstallPrompt);
    };
  });

  const askToInstall = useCallback(() => {
    if (isMobileSafari) {
      return navigate("/safari");
    }
    if (isSamsungBrowser) {
      return navigate("/samsung");
    }

    if (!deferredPrompt) {
      return navigate("/chrome");
    }
    setAddToHomeAvailable(false);

    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      deferredPrompt = null;
    });
  }, [navigate]);

  useEffect(() => {
    const clickBodyCallback = () => {
      if (addToHomeAvailable && Boolean(deferredPrompt)) {
        askToInstall();
      }
    };
    document.body.addEventListener("click", clickBodyCallback, true);
    return () => {
      document.body.removeEventListener("click", clickBodyCallback, true);
    };
  }, [addToHomeAvailable, askToInstall]);

  return {
    addToHomeAvailable,
    askToInstall,
  };
};

import * as React from "react"
import { SVGProps } from "react"

const BackArrowSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 12.463 0 6.23 6 0l1.069 1.09-4.2 4.363H12V7.01H2.869l4.2 4.362L6 12.462Z"
      fill="#525252"
    />
  </svg>
)

export default BackArrowSvg

import * as React from "react"
import { SVGProps } from "react"

const MessageSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={42}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.333 14.75H12.667M29.333 21H12.667m16.666 6.25h-6.25M2.25 21a18.75 18.75 0 0 0 28.781 15.844l8.719 2.906-2.906-8.719A18.75 18.75 0 1 0 2.25 21v0Z"
      stroke="#3F2B8B"
      strokeWidth={3.167}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MessageSvg

import * as React from "react"
import { SVGProps } from "react"

const PhoneSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 4.5c10.77 0 19.5 8.73 19.5 19.5S34.77 43.5 24 43.5 4.5 34.77 4.5 24 13.23 4.5 24 4.5Z"
      stroke="#3F2B8B"
      strokeWidth={3}
    />
    <path
      d="M25.76 30.094a15.673 15.673 0 0 1-6.864-6.865l2.635-2.635-.635-5.469h-5.74c-.604 10.604 8.115 19.323 18.72 18.719v-5.74l-5.49-.635-2.625 2.625Z"
      fill="#3F2B8B"
    />
  </svg>
)

export default PhoneSvg

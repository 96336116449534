import "./App.css";
import NavbarPage from "./components/NavBarPage";
import Home from "./screens/Home";
import FormScreen from "./screens/FormScreen";
import Safari from "./screens/Safari";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chrome from "./screens/Chrome";
import Samsung from "./screens/Samsung";

function App() {
  return (
    <Router>
      <div className="App">
        <NavbarPage />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form" element={<FormScreen />} />
          <Route path="/safari" element={<Safari />} />
          <Route path="/chrome" element={<Chrome />} />
          <Route path="/samsung" element={<Samsung />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

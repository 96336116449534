import { Button } from "react-bootstrap";
import { useAddToHome } from "../utils/useAddToHome";

export function AddToHomeButton() {
  const { addToHomeAvailable, askToInstall } = useAddToHome();

  if (!addToHomeAvailable) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      onClick={askToInstall}
      style={{
        margin: "10px 0",
        background: "#525252",
      }}
    >
      Dodaj do ekranu głównego
    </Button>
  );
}

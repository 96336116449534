import * as React from "react"
import { SVGProps } from "react"

const ValidSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 2a7 7 0 1 0 .001 14.001A7 7 0 0 0 9 2Zm-.5 3.625c0-.069.056-.125.125-.125h.75c.069 0 .125.056.125.125v4.25a.125.125 0 0 1-.125.125h-.75a.125.125 0 0 1-.125-.125v-4.25ZM9 12.5A.75.75 0 0 1 9 11a.75.75 0 0 1 0 1.5Z"
      fill="#E10101"
    />
  </svg>
)

export default ValidSvg

import { BackNavigation } from "../components/BackNavigation";
import styles from "./Instructions.module.css";

const Samsung = () => {
  return (
    <section>
      <BackNavigation />
      <div className={styles.container}>
        <img height={600} src={require("../img/samsung.gif")} alt="samsung 1" />
      </div>
      <div style={{ height: 80 }} />
    </section>
  );
};
export default Samsung;

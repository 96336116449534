import * as React from "react"
import { SVGProps } from "react"

const PersonSvg= (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={26}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 29.833H2c-.52 0-.955-.176-1.307-.528-.35-.35-.526-.786-.526-1.305v-4.308c0-.611.13-1.177.39-1.696.26-.52.633-.947 1.122-1.284 1.559-1.16 3.354-2.07 5.387-2.727C9.096 17.328 11.076 17 13 17c1.925 0 3.904.328 5.936.985s3.827 1.566 5.385 2.727a3.37 3.37 0 0 1 1.124 1.284c.259.52.388 1.085.388 1.696V28c0 .52-.176.955-.528 1.305a1.77 1.77 0 0 1-1.305.528ZM3.833 26.167h18.334v-2.475c-1.284-.948-2.735-1.688-4.354-2.222-1.62-.536-3.224-.803-4.813-.803-1.589 0-3.193.26-4.812.779-1.62.52-3.071 1.253-4.355 2.2v2.52Zm9.167-11c-2.017 0-3.743-.718-5.18-2.155-1.435-1.436-2.153-3.162-2.153-5.179 0-2.016.718-3.743 2.154-5.179C9.257 1.218 10.983.5 13 .5c2.016 0 3.742.718 5.178 2.154s2.154 3.163 2.154 5.18c0 2.016-.718 3.742-2.154 5.178-1.436 1.437-3.162 2.155-5.179 2.155Zm0-3.667c1.008 0 1.872-.36 2.59-1.078.718-.717 1.077-1.58 1.077-2.589 0-1.008-.359-1.87-1.076-2.588-.72-.719-1.583-1.078-2.59-1.078-1.01 0-1.872.359-2.59 1.078-.718.717-1.078 1.58-1.078 2.588 0 1.009.36 1.872 1.078 2.589.718.719 1.58 1.078 2.59 1.078Z"
      fill="#1A1818"
      fillOpacity={0.3}
    />
  </svg>
)

export default PersonSvg

import { BackNavigation } from "../components/BackNavigation";
import styles from "./Instructions.module.css";

const Chrome = () => {
  return (
    <section>
      <BackNavigation />
      <div className={styles.container}>
        <img height={600} src={require("../img/chrome.gif")} alt="chrome 1" />
      </div>
      <div style={{ height: 80 }} />
    </section>
  );
};
export default Chrome;

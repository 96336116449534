import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";

function Footer() {
  return (
    <div
      style={{
        boxShadow: "5px 2px 5px gray",
        paddingTop: 28,
        backgroundColor: "#fff",
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Container style={{ justifyContent: "center" }}>
        <a href="https://www.largo.com.pl/">
          <Button
            style={{
              backgroundColor: "#3F2B8B",
              paddingLeft: 35,
              paddingRight: 35,
              borderRadius: 15,
              borderWidth: 0,
              paddingTop: 10,
              paddingBottom: 10,
              marginBottom: 30,
            }}
          >
            Przejdź na stronę largo.com.pl
          </Button>
        </a>
      </Container>
    </div>
  );
}

export default Footer;

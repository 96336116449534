import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

function NavbarPage() {
  return (
    <Navbar className="navbar navbar-light bg-white shadow-sm p-3 mb-2 bg-white rounded">
      <Container
        className="navbar"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <img
          src={require("../img/logo.png")}
          alt="Logo"
          style={{ width: 100, height: 70 }}
        />
        <h1 style={{ fontSize: '1.125rem', opacity: 0.7 }}>
          Sklep z częściami do wind załadowczych
        </h1>
      </Container>
    </Navbar>
  );
}

export default NavbarPage;

import { useEffect, useState } from "react";

import PersonSvg from "../svg/PersonSvg";
import PhoneSvg from "../svg/PhoneSvg";
import MessageSvg from "../svg/MessageSvg";
import EnvelopeSvg from "../svg/EnvelopeSvg";
import { Link } from "react-router-dom";
import { AddToHomeButton } from "../components/AddToHomeButton";
import Footer from "../components/Footer";

function Home() {
  //  addToHomeScreen()

  const [users, setUsers] = useState<
    { name: string; number: string; email: string; img: string | null }[]
  >([]);
  useEffect(() => {
    fetch("users/users.json")
      .then((res) => res.json())
      .then((data) => setUsers(data));
  }, []);

  return (
    <>
      <AddToHomeButton />
      <div className="home">
        {users
          ? users.map((item) => {
              return (
                <div
                  key={[item.name, item.number, item.email].join("-")}
                  className="bg-white border-bottom"
                  style={{
                    padding: 10,
                    justifyContent: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      paddingLeft: 20,
                      paddingRight: 40,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 120,
                      }}
                    >
                      <div
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 500,
                          backgroundColor: "#DDDDDD",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {item.img ? (
                          <img
                            src={item.img}
                            alt={item.name}
                            width="60"
                            height="60"
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <PersonSvg />
                        )}
                      </div>
                      <text style={{ fontWeight: 500, fontSize: 14 }}>
                        {item.name}
                      </text>
                    </div>

                    <a
                      href={`tel:${item.number}`}
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <PhoneSvg />
                    </a>
                    <Link
                      to={{ pathname: "/form" }}
                      state={{ number: item.number }}
                      className="social-link"
                      style={{
                        marginBottom: 20,
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                    >
                      <MessageSvg />
                    </Link>
                    <Link
                      to={{ pathname: "/form" }}
                      state={{ email: item.email }}
                      className="social-link"
                      style={{ marginBottom: 20 }}
                    >
                      <EnvelopeSvg />
                    </Link>
                  </div>
                </div>
              );
            })
          : null}
        {/* Empty height div to match footer height */}
        {users?.length > 2 && <div style={{ height: 100 }} />}
        <Footer />
      </div>
    </>
  );
}
export default Home;

import * as React from "react"
import { SVGProps } from "react"

const EnvelopeSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={41}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m18.834 17.94-.835 1.314.835-1.315Zm3.332 0 .835 1.314-.835-1.315ZM4.393 3.2h32.214V0H4.393v3.2ZM38.07 4.8v22.4H41V4.8h-2.929Zm-1.464 24H4.393V32h32.214v-3.2ZM2.93 27.2V4.8H0v22.4h2.929Zm1.464 1.6c-.388 0-.76-.169-1.036-.469A1.679 1.679 0 0 1 2.93 27.2H0c0 1.273.463 2.494 1.287 3.394.823.9 1.94 1.406 3.106 1.406v-3.2Zm33.678-1.6c0 .424-.154.831-.428 1.131-.275.3-.648.469-1.036.469V32c1.165 0 2.282-.506 3.106-1.406.824-.9 1.287-2.121 1.287-3.394h-2.929Zm-1.464-24c.389 0 .76.169 1.036.469.274.3.428.707.428 1.131H41c0-1.273-.463-2.494-1.287-3.394C38.89.506 37.773 0 36.607 0v3.2ZM4.393 0C3.228 0 2.11.506 1.287 1.406.463 2.306 0 3.527 0 4.8h2.929c0-.424.154-.831.428-1.131.275-.3.647-.469 1.036-.469V0ZM.63 6.115l17.369 13.14 1.668-2.631-17.37-13.14L.631 6.116H.63ZM23 19.255l17.368-13.14-1.666-2.63-17.37 13.139 1.668 2.63Zm-5.002 0a4.139 4.139 0 0 0 2.501.853c.894 0 1.766-.298 2.501-.854l-1.668-2.63a1.38 1.38 0 0 1-.833.284c-.298 0-.588-.099-.833-.284l-1.668 2.63Z"
      fill="#3F2B8B"
    />
  </svg>
)

export default EnvelopeSvg
